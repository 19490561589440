
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { Component, Vue } from 'vue-property-decorator';
import CiTable, { DATA_TYPE } from '@/modules/common/components/ci-table';
import { ITableData } from '@/modules/common/components/ci-table/interfaces';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type HelperService from '@/modules/common/services/helper.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import type Day from '@/modules/common/types/day.type';
import PriceFilter from '@/modules/common/filters/price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import { BRAND_AS_BROKER_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import type UserService from '@/modules/user/user.service';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import _ from 'lodash';

enum CarTableColumn {
    Date = 'Date',
    Events = 'Events',
    Availability = 'Availability',
    Median = 'Median',
    Diff = 'Diff',
}

@Component({
    components: {
        CiTable,
    },
})
export default class CarTableV2 extends Vue {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(KEY.UserService) private userService!: UserService;

    readonly tableConfig = {
        height: '100%',
        width: '100%',
        cellSize: [{
            width: ['90px', '120px', '100px', '100px', '120px', '120px', '200px'],
            height: ['50px'],
        }, {
            width: ['200px'],
            height: ['50px'],
        }],
    };

    get currency(): string | null {
        const { currency } = this.carsService;
        return currency ? this.helperService.currencySymbol(currency) : '';
    }

    get average() {
        const daysCount = this.documentFiltersService.days.length;
        let availableDaysCount = 0;
        const res = this.documentFiltersService.days
            .map(day => {
                const percent = this.carsService.occupancy(day);
                const { totalCars, countCars } = this.carsService.fleetAvailability(day);
                return {
                    countCars,
                    totalCars,
                    percent,
                };
            })
            .reduce((cur, {
                countCars,
                totalCars,
                percent,
            }, i) => {
                if (percent) {
                    availableDaysCount += 1;
                }
                return {
                    countCars: ((countCars || 0) + cur.countCars) / (i === daysCount - 1 ? availableDaysCount : 1),
                    totalCars: ((totalCars || 0) + cur.totalCars) / (i === daysCount - 1 ? availableDaysCount : 1),
                    percent: ((percent || 0) + cur.percent) / (i === daysCount - 1 ? availableDaysCount : 1),
                };
            }, {
                countCars: 0,
                totalCars: 0,
                percent: 0,
            });
        return {
            countCars: Math.round(res.countCars),
            totalCars: Math.round(res.totalCars),
            percent: Math.round(res.percent),
        };
    }

    private transformDate(day: number): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const d = new Date(year, month, day);
        const dayName = d.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();

        return `${this.$t(dayName)} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    private medianPrice(day: Day) {
        const price = this.carsService.competitorMedian(day);
        return price ? PriceFilter(price) : '';
    }

    get competitorsCompanies() {
        const { competitorsFilter } = this.carsFiltersService;
        const { competitors } = this.carsService.storeState.settings;
        const { brokersCompetitors, isBrokerToBroker } = this.carsService;
        if (isBrokerToBroker) {
            return brokersCompetitors || [];
        }
        if (!competitors) {
            return [];
        }
        return competitors
            .filter(item => competitorsFilter.includes(item));
    }

    get currentCompany() {
        const { isBrokerToBrand, currentBrandAsBroker } = this.carsService;
        if (isBrokerToBrand) {
            return (currentBrandAsBroker === BRAND_AS_BROKER_ANY ? this.userService.currentCompany : currentBrandAsBroker) || '';
        }
        return this.userService.currentCompany || '';
    }

    handleRowClick(day: Day) {
        this.$router.push({ name: 'cars-rates.table.day-popup', params: { day: String(day) } });
    }

    getCarData(day: Day, company: string) {
        return this.carsService.getCarDataTable(day, company);
    }

    hasAvailablePrice(day: Day, company: string) {
        const car = this.getCarData(day, company) as CarsDocumentItemModel;
        return car.isAvailable && !!this.carsService.getCarPrice(car, company);
    }

    getPriceData(day: Day, competitor: string) {
        const car = this.getCarData(day, competitor) as CarsDocumentItemModel;
        const price = this.carsService.getCarPrice(car, competitor);
        const isNoData = this.carsService.isNoData(day);
        if (isNoData) {
            return 'Out of range';
        }
        if (this.carsService.allCars(day) === false || price === 0) {
            return 'N/A';
        }
        if (car.isAvailable && price) {
            const { isAvgPrice } = this.carsService.storeState.settings;
            const { currency } = this.carsService;
            const currencySymbol = this.helperService.currencySymbol(currency);
            return `${currencySymbol} ${MIPriceFilter(price as number)} ${isAvgPrice ? '(avg.)' : ''}`;
        }
        return 'Sold Out';
    }

    get tableData(): ITableData {
        const { days } = this.documentFiltersService;
        return [
            {
                isSticky: true,
                columns: [
                    {
                        title: CarTableColumn.Date,
                        data: days.map(day => ({
                            value: this.transformDate(day),
                            onClick: () => this.handleRowClick(day),
                        })),
                    }, {
                        title: CarTableColumn.Events,
                        dataType: DATA_TYPE.EVENT,
                        data: days.map(day => ({
                            onClick: () => this.handleRowClick(day),
                        })),
                    }, {
                        title: CarTableColumn.Availability,
                        dataType: DATA_TYPE.OCCUPANCY,
                        data: days.map(day => {
                            const occupancy = Number(this.carsService.occupancy(day));
                            return {
                                value: occupancy ? `${occupancy}%` : '',
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                        titleStyle: { display: 'flex' },
                        options: {
                            occupancy: {
                                title: `Fleet Availability\n${this.average.countCars}\u00A0out\u00A0of\u00A0`
                                    + `${this.average.totalCars}\u00A0(${this.average.percent}%)`,
                                percent: this.average.percent / 100,
                            },
                        },
                    }, {
                        title: CarTableColumn.Median,
                        data: days.map(day => {
                            const median = this.medianPrice(day);
                            return {
                                value: median ? `${this.currency} ${median}` : '',
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                    }, {
                        title: CarTableColumn.Diff,
                        data: days.map(day => {
                            const percent = this.carsService.competitorPercent(day);
                            const assessment = this.carsService.getCardAssessment(percent);
                            const color = _.get({
                                [ASSESSMENT_TYPES.BAD]: '#E7472D',
                                [ASSESSMENT_TYPES.GOOD]: '#01B875',
                            }, assessment || '', null);

                            if (!this.carsService.showDiff) {
                                return {
                                    value: percent ? `${PercentFilter(percent)}%` : '',
                                    onClick: () => this.handleRowClick(day),
                                    style: { color },
                                };
                            }
                            const { currency } = this.carsService;
                            const car = this.getCarData(day, this.currentCompany) as CarsDocumentItemModel;
                            const price = this.carsService.getCarPrice(car, this.currentCompany);
                            const competitorPrice = this.carsService.competitorPrice(day);
                            const currencySymbol = this.helperService.currencySymbol(currency);
                            const diff = _.isNumber(price) && _.isNumber(competitorPrice)
                                ? (price || 0) - (competitorPrice)
                                : null;

                            return {
                                value: diff ? `${currencySymbol} ${MIPriceFilter(Math.abs(diff as number))}` : '',
                                onClick: () => this.handleRowClick(day),
                                style: { color },
                            };
                        }),
                    },
                    {
                        title: this.currentCompany,
                        titleStyle: { color: '#00759e' },
                        dynamicColumns: [
                            CarTableColumn.Events,
                            this.carsFiltersService.isAvailability ? [CarTableColumn.Availability] : [],
                            CarTableColumn.Median,
                            CarTableColumn.Diff,
                        ].flat(),
                        data: days.map(day => {
                            const value = this.getPriceData(day, this.currentCompany);
                            return {
                                value,
                                color: ['Out of range', 'N/A', 'Sold Out'].includes(value) ? '#818D98' : null,
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                    },
                ].filter(col => this.carsFiltersService.isAvailability || col.title !== CarTableColumn.Availability),
            },
            {
                columns: this.competitorsCompanies.map(competitor => ({
                    title: competitor,
                    data: days.map(day => {
                        const car = this.getCarData(day, competitor) as CarsDocumentItemModel;
                        const price = this.carsService.getCarPrice(car, competitor);
                        const value = this.getPriceData(day, competitor);
                        const assessmentType = price ? this.carsService.getTableAssessment(price, day) : null;
                        const color = ['Out of range', 'N/A', 'Sold Out'].includes(value)
                            ? '#818D98'
                            : _.get({
                                [ASSESSMENT_TYPES.BAD]: '#E7472D',
                                [ASSESSMENT_TYPES.GOOD]: '#01B875',
                            }, assessmentType || '', null);

                        return {
                            value,
                            style: {
                                color,
                            },
                            onClick: () => this.handleRowClick(day),
                        };
                    }),
                })),
            },
        ];
    }
}
